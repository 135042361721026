.nav-down {
    position: fixed;
    top: 0;
    transition: top 0.5s ease-in-out;
    width: 100%;
}

.nav-up {
    top: -80px;
}

.no-dot {
    list-style-type: none;
}